body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "noah";
    src: local("noah"), url(./theme/fonts/noah-regular.otf) format("truetype");
}

@font-face {
    font-family: "noah-bold";
    src: local("noah-bold"), url(./theme/fonts/noah-bold.otf) format("truetype");
}

@font-face {
    font-family: "noah-italic";
    src: local("noah-italic"),
        url(./theme/fonts/noah-regularitalic.otf) format("truetype");
}

* {
    font-family: noah;
    box-sizing: border-box;
    margin: 0;

    scrollbar-color: #339d4a #d9d9d9;
    scrollbar-width: thin;
}
